import { GetGraphSales } from 'core/API/laborsaber';
import { useQuery } from 'react-query';

export const useSalesGraph = (workkplaceId, firstBusinessDay, lastBusinessDay) => {
	const queryData = useQuery<any>('employeeGraphSales', async () => {
		const graphResponse = await GetGraphSales({
			workkplaceID: workkplaceId,
			formatedFirstDay: firstBusinessDay,
			formatedLastDay: lastBusinessDay,
		});

		return graphResponse;
	});

	return queryData;
};
