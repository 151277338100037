import {
	CalendarNav,
	CalendarNext,
	CalendarPrev,
	CalendarToday,
	MbscCalendarEventData,
	MbscResource,
} from '@mobiscroll/react';

export const EventResource = (resource: MbscResource) => {
	return (
		<div className={`md-work-week-cont `}>
			<div className="md-work-week-name">{resource.name}</div>
			<div className="md-work-week-title">{resource?.role?.map(({ name }: any) => `${name} `)}</div>
		</div>
	);
};

export const EventHeader = ({ view, workplaces, change }) => {
	return (
		<>
			<div className="customHeader">
				<div className="customHeaderTop">
					<CalendarNav className="md-work-week-nav" />
					<div className="md-work-week-picker">
						<div className="toggleSchdule">
							<button className={`${view === 'day' ? 'active' : null}`} onClick={() => change({ view: 'day' })}>
								Day
							</button>
							<button className={`${view === 'week' ? 'active' : null}`} onClick={() => change({ view: 'week' })}>
								Week
							</button>
						</div>
					</div>
					<div className="time-navigation-container">
						<CalendarPrev className="md-work-week-prev" />
						<CalendarToday className="md-work-week-today" />
						<CalendarNext className="md-work-week-next" />
					</div>
				</div>
				<div className="customHeaderBottom">
					<span className="WorkplaceTitle">
						Workplace:
						<span className="WorkplaceName">{workplaces}</span>
					</span>
				</div>
			</div>
		</>
	);
};

export const Event = (data: MbscCalendarEventData) => {
	const original = data.original!;

	const eventClass = [
		'md-timeline-template-event',
		original.overlap && 'hasStripeBackground',
		original.isTradeSelected && 'trade-outline',
		!original.scheduled && 'requestsEventCustom',
	]
		.filter(Boolean)
		.join(' ');

	return (
		<button
			className={eventClass}
			style={{
				cursor: 'pointer',
				backgroundColor: original?.background_color,
				color: original?.text_color,
			}}
		>
			<div className="md-timeline-template-event-cont">
				<div className="md-timeline-template-time">
					{data.start} - {data.end}
				</div>
				<div className="md-timeline-template-role">{original?.role ?? 'No Role'}</div>
			</div>
		</button>
	);
};
