import React, { useState } from 'react';
import { ReactComponent as HappyFace } from 'assets/icons/HappyFace.svg';
import { ReactComponent as SmileFace } from 'assets/icons/SmileFace.svg';
import { ReactComponent as AverageFace } from 'assets/icons/AverageFace.svg';
import { ReactComponent as PoorFace } from 'assets/icons/PoorFace.svg';

import style from './QuestionComp.module.scss';
import { GuestConnectField } from 'module/guestconnect/interface/IGuestConnect';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';

interface QuestionCompProps extends IFooterButtons {
	value: GuestConnectField;
	numberOfQuestion: number;
	questionsLength: number;
	questionHandler: (identifier: string, answer: string) => void;
}

export default function QuestionComp({
	value,
	numberOfQuestion,
	questionsLength,
	questionHandler,
	goNext,
	goBack,
}: QuestionCompProps): JSX.Element {
	const [answer, setAnswer] = useState('');

	return (
		<>
			<div className={style.TextInfo}>
				<div className={style.firstColumn}>
					<p className={style.infoSmall}>Your opinion is important to us!</p>
					<p className={style.infoBig}>{value.label}</p>
				</div>
				<div className={style.secondColumn}>
					{numberOfQuestion}/{questionsLength}
				</div>
			</div>
			<div
				className={`${style.SelectButtons} ${
					value.answer === 'Excellent' || answer === 'Excellent' ? style.SelectedButtonColor : null
				}`}
				onClick={() => {
					setAnswer('Excellent');
					questionHandler(value.identifier, 'Excellent');
				}}
			>
				<HappyFace />
				<p>Excellent</p>
			</div>
			<div
				className={`${style.SelectButtons} ${
					value.answer === 'Good' || answer === 'Good' ? style.SelectedButtonColor : null
				}`}
				onClick={() => {
					setAnswer('Good');
					questionHandler(value.identifier, 'Good');
				}}
			>
				<SmileFace />
				<p>Good</p>
			</div>
			<div
				className={`${style.SelectButtons} ${
					value.answer === 'Average' || answer === 'Average' ? style.SelectedButtonColor : null
				}`}
				onClick={() => {
					setAnswer('Average');
					questionHandler(value.identifier, 'Average');
				}}
			>
				<AverageFace />
				<p>Average</p>
			</div>
			<div
				className={`${style.SelectButtons} ${
					value.answer === 'Poor' || answer === 'Poor' ? style.SelectedButtonColor : null
				}`}
				onClick={() => {
					setAnswer('Poor');
					questionHandler(value.identifier, 'Poor');
				}}
			>
				<PoorFace />
				<p>Poor</p>
			</div>

			<FooterButtons
				goNext={goNext}
				goBack={goBack}
				isDisabled={answer === '' && value.answer === ''}
				showPrevious={numberOfQuestion !== 1}
				label="Next"
			/>
		</>
	);
}
