import { useFormik } from 'formik';
// CSS modules
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ClipLoader from 'react-spinners/ClipLoader';

import bin from 'assets/icons/Bin.svg';
import { GetDepartmentsListOnWorkplace } from 'core/API/departments';
import {
	DeleteShift,
	DeleteShiftsAddRole,
	GetOneShift,
	IPutOneShift,
	PostShifts,
	PostShiftsAddRole,
	PutOneShift,
} from 'core/API/shifts';
import Loading from 'core/components/Loading/Loading';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useIsMount } from 'core/hooks/useIsMounted';
import { IUsers } from 'core/interfaces/userInterface';
import { Item as IDepartmentItem } from 'module/preload/interfaces/DepartmentsInterface';
import { Item } from 'module/preload/interfaces/RolesInterface';
import { IDays } from 'module/schedule/interfaces/DaysInterface';
import ConfirmDialogBox from 'module/schedule/Modals/ConfirmDialog/ConfirmDialog';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { Item as WorkplaceItem } from 'module/workplaces/interface/WorkplacesInterface';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import { formatTime, generateTimeArray } from 'shared/utils/dates';
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';
import Icons from 'styles/icons.module.scss';

import { ICellClick } from '../../pages/SchedulePage';

export interface IShift {
	workplace: number;
	role: number;
	time_card_id: number;
	open: boolean;
	department: number;
	user: number;
	published: boolean;
	scheduled: boolean;
	start_at: string;
	end_at: string;
	note: string;
	recurring: boolean;
	billable: boolean;
}

interface IAddShiftModal {
	closeAddShiftModal: () => void;
	editEvent?: any;
	addNewEvent?: ICellClick;
	fromDate: string;
}

export default function AddShiftModal(props: IAddShiftModal): JSX.Element {
	const { showToast } = useToastContext();
	const queryClient = useQueryClient();

	const isMounted = useIsMount();

	// get preselected workplace
	const { workkplaceID, timezone, data: workplacesData } = useWorkplacesContext();

	const [view, setView] = useState('Pref');
	const [selectedWorkplace, setSelectedWorkplace] = useState(workkplaceID);

	const [, setDayToday] = useState(0);
	const [days, setDays] = useState<IDays[]>([
		{ id: 1, label: 'M', active: false, date: null },
		{ id: 2, label: 'T', active: false, date: null },
		{ id: 3, label: 'W', active: false, date: null },
		{ id: 4, label: 'T', active: false, date: null },
		{ id: 5, label: 'F', active: false, date: null },
		{ id: 6, label: 'S', active: false, date: null },
		{ id: 7, label: 'S', active: false, date: null },
	]);

	const [departemntSelected, setdepartemntSelected] = useState<any>(null);
	const [roleSelected, setRoleSelected] = useState<any>(null);

	const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
	const [timeEditStartAt, setTimeEditStartAt] = useState('00:00');
	const [timeEditEndAt, setTimeEditEndAt] = useState('00:00');

	const usersData = queryClient.getQueryData<IUsers[]>(['users', selectedWorkplace]);

	const { data: workplaceDepartments, isFetching: isLoadingWorkplace } = useQuery(
		['workplace-departments', selectedWorkplace],
		() => GetDepartmentsListOnWorkplace(selectedWorkplace)
	);

	const { data: shiftData, isLoading: shiftLoading } = useQuery(
		['shift-one', props?.editEvent?.shift_id],
		() => GetOneShift(props.editEvent.shift_id!),
		{
			enabled: props.editEvent !== undefined,
			onSuccess: (data) => {
				// create time formats for dropdown to display real data on edit shift
				// or when event is draged over calendar or resize event
				const d1 = new Date(data.start_at);
				const d2 = new Date(props.editEvent.start);
				if (d1.getTime() !== d2.getTime()) {
					// when draged left right or when star date is draged
					setTimeEditStartAt(moment.tz(props.editEvent.start, timezone).format('HH:mm'));
					onLoadSelectDay(d2);
				} else {
					// doubble click enters here
					setTimeEditStartAt(moment.tz(data.start_at, timezone).format('HH:mm'));
					onLoadSelectDay(d1);
				}

				const d3 = new Date(data.end_at);
				const d4 = new Date(props.editEvent.end);

				if (d3.getTime() !== d4.getTime()) {
					const endTimeString = moment.tz(props.editEvent.end, timezone).format('HH:mm');

					const splitEndTime = endTimeString.split(':');
					if (endTimeString === '00:00') {
						// ako je oduzmi minutu
						setTimeEditEndAt(moment.tz(props.editEvent.end, timezone).subtract(1, 'minute').format('HH:mm'));
					} else if (splitEndTime[1] === '59') {
						setTimeEditEndAt(moment.tz(props.editEvent.end, timezone).add(1, 'minute').format('HH:mm'));
					} else {
						// obicno dodavanje
						setTimeEditEndAt(endTimeString);
					}
				} else {
					setTimeEditEndAt(moment.tz(data.end_at, timezone).format('HH:mm'));
				}
			},
		}
	);

	const { mutate, isLoading } = useMutation(PostShifts, {
		onSuccess: (e) => {
			// for every mutate request succesfull add according role to it
			// can use it in mutate below because it will only run once
			// and we need it here to be run for every day selected in form
			addRole(
				{ shift: e.id, role: formik.values.role },
				{
					onSuccess: () => {
						showToast('success', 'Shift successfully added!');
						props.closeAddShiftModal();
					},
					onError: () => {
						showToast('error');
					},
				}
			);
		},
		onError: (error: Error) => {
			showToast('error', error.message || 'Something went wrong!');
		},
	});
	const { mutate: addRole } = useMutation(PostShiftsAddRole);
	const { mutate: deleteShift, isLoading: loadingDeleteShift } = useMutation(DeleteShift);

	const { mutate: deleteRole } = useMutation(DeleteShiftsAddRole);

	const { mutate: updateOneShift, isLoading: loadingUpdateOneShift } = useMutation(PutOneShift);

	useEffect(() => {
		if (isMounted) {
			const curr = new Date(props.fromDate.replace(/-/g, '/')); // get current date
			const first = curr.getDate() - curr.getDay() + 1;

			const firstday = new Date(curr.setDate(first));

			const dt = new Date(firstday);
			let brojac = 0;
			const arr = [];

			const copyOfDays = [...days];
			while (brojac < 7) {
				arr.push(new Date(dt));
				copyOfDays[brojac].date = new Date(dt);
				dt.setDate(dt.getDate() + 1);
				brojac++;
			}

			setDays(copyOfDays);
		}
	}, []);

	useEffect(() => {
		if (isMounted) {
			if (props.editEvent?.resource && props.editEvent?.department) {
				let dep;
				workplaceDepartments?.forEach((value) => {
					if (value.department.id === props.editEvent?.department) {
						dep = value.department;
					}
				});
				setdepartemntSelected(dep);
				setRoleSelected(props.editEvent?.roles);
				onLoadSelectDay(props.editEvent.start_at);
			}
			// when edit function calculate selected day
			else if (!props.editEvent?.shift_id) {
				onLoadSelectDay();
			}
		}
	}, [workplaceDepartments]);

	const onLoadSelectDay = (date = new Date()) => {
		const dateToday = date;
		const dayToday = dateToday.getDay() !== 0 ? dateToday.getDay() : 7;
		selectDay(dayToday);

		// set day today as global so we can disable selecting days in the past
		setDayToday(dayToday);
	};

	const selectDay = (id: number) => {
		if (props.editEvent?.shift_id) {
			// only select one day on edit form
			let newArrDays = days.map((value) => ({ ...value, active: false }));
			newArrDays = newArrDays.map((value) => {
				if (value.id === id) {
					// disable select days in past
					value.active = !value.active;

					return {
						...value,
					};
				}

				return value;
			});

			setDays(newArrDays);
		} else {
			const newArrDays = days.map((value) => {
				if (value.id === id) {
					// disable select days in past
					value.active = !value.active;

					return {
						...value,
					};
				}

				return value;
			});

			setDays(newArrDays);
		}
	};

	const formik = useFormik<IShift>({
		initialValues: {
			workplace: workkplaceID,
			role: shiftData?.roles[0]?.id || props.addNewEvent?.roles || 0,
			department: shiftData?.department.id || props.addNewEvent?.department || 0,
			user: shiftData?.user?.id
				? shiftData?.user?.id
				: props?.addNewEvent?.resource
				  ? props?.addNewEvent?.resource
				  : props?.editEvent?.resource
				    ? props?.editEvent?.resource
				    : 0,
			time_card_id: 0,
			open: true,
			published: shiftData?.published ? shiftData.published : false,
			scheduled: true,
			start_at: shiftData?.start_at
				? timeEditStartAt
				: props?.addNewEvent?.start_at
				  ? formatTime(props?.addNewEvent?.start_at)
				  : '00:00',
			end_at: shiftData?.end_at
				? timeEditEndAt
				: props?.addNewEvent?.end_at
				  ? formatTime(props?.addNewEvent?.end_at)
				  : '00:00',
			note: shiftData?.note || '',
			recurring: shiftData?.recurring,
			billable: shiftData?.billable,
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const shiftsToSend = generateArryaOfShifts();
			try {
				if (!values.role) throw new Error('Role was not selected!');
				shiftsToSend.forEach((value) => {
					if (value.body.start_at !== value.body.end_at) {
						if (props.editEvent?.shift_id) {
							updateOneShift(value, {
								onSuccess: (updateResponse) => {
									deleteRole(
										{
											shift: updateResponse.id,
											role: updateResponse.roles[0].id,
										},
										{
											onSuccess: () => {
												addRole(
													{
														shift: updateResponse.id,
														role: values.role,
													},
													{
														onSuccess: () => {
															showToast('success', 'Shift successfully updated!');
															props.closeAddShiftModal();
														},
														onError: () => {
															showToast('error');
														},
													}
												);
											},
											onError: () => {
												showToast('error');
											},
										}
									);
								},
								onError: () => {
									showToast('error');
								},
							});
						} else {
							mutate({
								...value.body,
								role: { id: roleSelected },
							});
						}
					} else {
						throw new Error('Something went wrong. Please check time format.');
					}
				});
			} catch (error: any) {
				showToast('error', error.message);
				props.closeAddShiftModal();
			}
		},
	});

	const generateArryaOfShifts = () => {
		// when multiple days selected create array of shifts for API
		const activeDays = days.filter((day) => day.active);
		const arrShifts: IPutOneShift[] = [];
		activeDays.forEach((day) => {
			console.log(day);
			const date = new Date(day.date!);

			const start_at = new Date(date);
			start_at.setHours(parseInt(formik.values.start_at.split(':')[0]), parseInt(formik.values.start_at.split(':')[1]));

			const end_at = new Date(date);
			end_at.setHours(parseInt(formik.values.end_at.split(':')[0]), parseInt(formik.values.end_at.split(':')[1]));

			if (props?.addNewEvent?.start_at) {
				const dateOnClick = new Date(props.addNewEvent.start_at);
				// condition if time is changed after slick on calendar
				if (
					dateOnClick.getHours() !== +formik.values.start_at.split(':')[0] ||
					dateOnClick.getMinutes() !== +formik.values.start_at.split(':')[1]
				) {
					start_at.setHours(
						parseInt(formik.values.start_at.split(':')[0]),
						parseInt(formik.values.start_at.split(':')[1])
					);
				} else {
					start_at.setHours(dateOnClick.getHours(), dateOnClick.getMinutes());
				}
			}

			const fmt = 'MM/DD/YYYY HH:mm:ss';

			const newStartFormated = moment(start_at).format(fmt);
			const newEndFormated = moment(end_at).format(fmt);

			const newStart_at = moment.tz(newStartFormated, fmt, timezone).utc().toDate().toISOString();

			const newEnd_at = moment.tz(newEndFormated, fmt, timezone).utc().toDate().toISOString();

			arrShifts.push({
				id: props.editEvent?.shift_id !== undefined ? props.editEvent.shift_id : null,
				body: {
					workplace: {
						id: formik.values.workplace,
					},
					time_card_id: 0,
					department: {
						id: parseInt(`${formik.values.department}`),
					},
					user: +formik.values.user
						? {
								id: parseInt(`${formik.values.user}`),
						  }
						: null,
					open: formik.values.user ? true : false,
					published: shiftData?.published ? shiftData.published : false,
					scheduled: true,
					denied: false,
					labor: {
						wage_hourly_costs: 0,
						wage_hourly_overtime_costs: 0,
						scheduled_hours: moment(end_at).diff(moment(start_at), 'hours', true).toString(),
					},
					start_at: newStart_at.substring(0, newStart_at.length - 5),
					end_at: newEnd_at.substring(0, newEnd_at.length - 5),
					// start_at: start_at
					//     .toISOString()
					//     .substring(0, start_at.toISOString().length - 5),
					// end_at: end_at
					//     .toISOString()
					//     .substring(0, end_at.toISOString().length - 5),
					note: formik.values.note,
					shift_type: {
						id: view === 'Pref' ? 4 : 2, // use real ID later on
					},
					reason: null,
					category: null,
					recurring: formik.values.recurring,
					billable: formik.values.billable,
					predicted: true,
				},
			});
		});

		return arrShifts;
	};

	useEffect(() => {
		setSelectedWorkplace(formik.values.workplace);
	}, [formik.values.workplace]);

	useEffect(() => {
		if (props.editEvent && shiftData) {
			// on edit shift call this block (double tap on event or resize event
			// or drag and drop)

			// preselect department from department list for showing list of
			// roles in select
			let dep;
			workplaceDepartments?.forEach((value) => {
				if (value.department.id === shiftData.department.id) {
					dep = value.department;
				}
			});
			setdepartemntSelected(dep);
			setRoleSelected(shiftData?.roles[0]?.id);
		}
	}, [shiftData]);

	const handleDeleteClick = () => {
		deleteShift(props.editEvent.shift_id!, {
			onSuccess: () => {
				showToast('success', 'Shift deleted successfully!');
				props.closeAddShiftModal();
			},
			onError: () => {
				showToast('error');
			},
		});
	};

	const handleDepartmentChose = (e: any) => {
		// regualar form handler
		formik.handleChange(e);

		// select certain department for showing roles from that department
		// in select dropdown below
		let dep;
		workplaceDepartments?.forEach((value) => {
			if (value.department.id === parseInt(e.target.value)) {
				dep = value.department;
			}
		});
		setdepartemntSelected(dep);

		// when change department after selecting roles and user
		// needs to reset form values
		formik.setFieldValue('role', 0);
		formik.setFieldValue('user', 0);
		setRoleSelected(null);
	};

	const handleRoleChose = (e: any) => {
		formik.handleChange(e);

		// filter selected roles because we need ID from it so we can filter
		// users in below field with that certain roleID
		const role = departemntSelected?.roles?.filter(
			(value: IDepartmentItem) => value.id === parseInt(e.target.value)
		)[0];

		setRoleSelected(role.id);
	};

	return shiftLoading || isLoadingWorkplace ? (
		<Loading />
	) : (
		<>
			<div className={Forms.formTabGroup}>
				<p
					className={`${Forms.formTabGroupItem} ${view === 'Pref' ? Forms.formTabGroupItemActive : null}`}
					onClick={() => setView('Pref')}
				>
					{' '}
					Preferred
				</p>
				<p
					className={`${Forms.formTabGroupItem} ${view === 'Timeoff' ? Forms.formTabGroupItemActive : null}`}
					onClick={() => setView('Timeoff')}
				>
					Time off
				</p>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Workplace
				</label>
				<select
					className={Forms.formSelect}
					name="workplace"
					id="select"
					value={formik.values.workplace}
					onChange={formik.handleChange}
					disabled={true}
					// disabled={!!props.editEvent?.shift_id}
				>
					<option value="0" disabled>
						Choose workplace
					</option>
					{workplacesData?.items?.map((workplace: WorkplaceItem, index: number) => {
						return (
							<option value={workplace.workplace.id} key={workplace.workplace.id * index}>
								{workplace.workplace.name}
							</option>
						);
					})}
				</select>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Department
				</label>
				<select
					className={Forms.formSelect}
					name="department"
					id="select"
					value={formik.values.department}
					onChange={handleDepartmentChose}
					required
				>
					<option value="0" disabled>
						Choose department
					</option>
					{workplaceDepartments?.map(({ department }: any) => {
						return (
							<option value={department.id} key={department.id}>
								{department.name}
							</option>
						);
					})}
				</select>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Role
				</label>
				<select
					className={Forms.formSelect}
					name="role"
					id="select"
					value={formik.values.role}
					onChange={handleRoleChose}
					disabled={!formik.values.department}
				>
					<option value="0" disabled>
						Choose role
					</option>
					{departemntSelected?.roles?.map((role: Item) => {
						return (
							<option value={role.id} key={role.id}>
								{role.name}
							</option>
						);
					})}
				</select>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Full name
				</label>
				<select
					className={Forms.formSelect}
					name="user"
					id="select"
					value={formik.values.user}
					onChange={formik.handleChange}
					disabled={!roleSelected}
				>
					<option value={0}>Unassigned</option>
					{usersData?.map((user: IUsers) => {
						if (user.role.filter((value) => value.id === roleSelected).length) {
							return (
								<option value={user.user.id} key={user.id}>
									{user.user.first_name} {user.user.last_name}
								</option>
							);
						} else {
							return;
						}
					})}
				</select>
			</div>
			<div className={Forms.formGroupSplit}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="select">
						From
					</label>
					<select
						className={Forms.formSelect}
						name="start_at"
						id="select"
						value={formik.values.start_at}
						onChange={formik.handleChange}
					>
						{generateTimeArray().map((value, index) => (
							<option value={value} key={index}>
								{value}
							</option>
						))}
					</select>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="select">
						To
					</label>
					<select
						className={Forms.formSelect}
						name="end_at"
						id="select"
						value={formik.values.end_at}
						onChange={formik.handleChange}
						disabled={!(formik.values.start_at !== '00:00')}
					>
						{generateTimeArray(formik.values.start_at).map((value, index) => (
							<option value={value} key={index}>
								{value}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="days">
					Days
				</label>
				<div className={Forms.formChecker}>
					{days.map((value, index) => (
						<div
							id="days"
							className={`${Forms.formCheckerItem} ${value.active ? Forms.formCheckerItemActive : null}`}
							key={index}
							onClick={() => {
								selectDay(value.id);
							}}
						>
							<h5>{value.label}</h5>
						</div>
					))}
				</div>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Write note here"
					value={formik.values.note}
					onChange={formik.handleChange}
					maxLength={160}
				></textarea>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Recurring & Billable
				</label>
				<div className={Forms.formCheckboxWrapper}>
					<div className={Forms.formToggle}>
						<label>
							<input
								type="checkbox"
								id="recurring"
								name="recurring"
								defaultChecked={formik.values.recurring}
								onChange={() => formik.setFieldValue('recurring', !formik.values.recurring)}
							/>
							<span
								style={{
									display: 'inline-block',
									marginLeft: '10px',
								}}
							>
								Recurring
							</span>
						</label>
					</div>

					<div className={Forms.formToggle}>
						<label>
							<input
								type="checkbox"
								id="billable"
								name="billable"
								defaultChecked={formik.values.billable}
								onChange={() => formik.setFieldValue('billable', !formik.values.billable)}
							/>
							<span
								style={{
									display: 'inline-block',
									marginLeft: '10px',
								}}
							>
								Billable
							</span>
						</label>
					</div>
				</div>
			</div>

			<footer className={Forms.formFooter}>
				{isLoading || loadingUpdateOneShift ? (
					<div>
						<ClipLoader color="#841D80" loading={true} size={50} />
					</div>
				) : (
					<>
						{props.editEvent?.shift_id ? (
							<button className={Buttons.btnDelete} onClick={() => setConfirmDeleteIsOpen(true)}>
								<SvgIcon spriteUrl={bin} className={Icons.btnErrorIcon} />
								Delete
							</button>
						) : null}
						{shiftData?.published ? null : (
							<button className={Buttons.btnText} onClick={props.closeAddShiftModal}>
								Cancel
							</button>
						)}
						{shiftData?.published ? (
							<button className={Buttons.btnPrimary} onClick={formik.submitForm}>
								Save and publish shift
							</button>
						) : (
							<button className={Buttons.btnPrimary} onClick={formik.submitForm}>
								Save shift
							</button>
						)}
					</>
				)}
			</footer>
			{confirmDeleteIsOpen ? (
				<ConfirmDialogBox
					type="shift"
					isLoading={loadingDeleteShift}
					handleDeleteClick={handleDeleteClick}
					setConfirmDeleteIsOpen={setConfirmDeleteIsOpen}
				/>
			) : null}
		</>
	);
}
