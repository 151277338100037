import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { GetDepartmentsListOnWorkplace } from 'core/API/departments';
import { DeleteShift, EditShifts, IPutOneShift, PostShifts, PostShiftsAddRole, Shift } from 'core/API/shifts';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useIsMount } from 'core/hooks/useIsMounted';
import { useAuth } from 'module/auth/context/AuthContext';
import { Item } from 'module/preload/interfaces/RolesInterface';
import { IDays } from 'module/schedule/interfaces/DaysInterface';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import ConfirmDialogBox from 'module/schedule/Modals/ConfirmDialog/ConfirmDialog';
// CSS modules
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';

import { MbscCalendarEvent } from '@mobiscroll/react';
import SaveChangesDialog from '../../../schedule/Modals/SaveChangesDialog/SaveChangesDialog';
import { IShiftsPost } from 'module/schedule/interfaces/ShiftsInterface';
import SvgIcon from 'shared/components/SvgIcon/SvgIcon';
import bin from 'assets/icons/Bin.svg';
import Icons from 'styles/icons.module.scss';

interface IAvailabilityModal {
	closeAvailibilityModal: () => void;
	defaultData: MbscCalendarEvent;
	fromDate: string;
}

const dateFormat = 'MM/DD/YYYY HH:mm:ss';

function getTimeInTimezone(dateString, timezone) {
	return dateString.split('T')[1].substring(0, 5);
}

const getDefaultProps = (defaultData, timezone: string) => {
	if (!defaultData) {
		const fallback = {
			start_at: '00:00',
			end_at: '00:00',
			note: '',
			department: 0,
			recurring: true,
			role: undefined,
		};

		return fallback;
	}

	const defaultState = {
		start_at: getTimeInTimezone(defaultData.start_at_with_time_zone, timezone),
		end_at: getTimeInTimezone(defaultData.end_at_with_time_zone, timezone),
		note: defaultData.note,
		department: defaultData.department.id,
		recurring: defaultData.recurring,
		role: defaultData.role.id,
	};

	return defaultState;
};

function generateTimeArray(date?: any) {
	const halfHours = ['00', '30'];
	const times = [];

	const preselectedHour = date !== undefined ? parseInt(date?.split(':')[0]) : 0;
	const preselectedMinutes = date !== undefined ? (date?.split(':')[1] === '00' ? 0 : 1) : 0;

	let counter = 0;
	for (let i = preselectedHour; i < 24; i++) {
		for (let j = 0; j < 2; j++) {
			if (counter++ === 0) {
				j = preselectedMinutes;
			}

			let time = i + ':' + halfHours[j];
			if (i < 10) {
				time = '0' + time;
			}
			times.push(time);
		}
	}

	times.push('23:59');
	return times;
}

export default function AvailabilityModal(props: IAvailabilityModal): JSX.Element {
	const { workkplaceID, timezone } = useWorkplacesContext();
	const { showToast } = useToastContext();
	const queryClient = useQueryClient();
	const isMounted = useIsMount();
	const auth = useAuth();

	const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);

	const isEditMode = Boolean(props.defaultData);

	const activeShift = props.defaultData?.shift_id
		? (queryClient.getQueryState(`shifts/${props.defaultData.shift_id}`)?.data as Shift)
		: undefined;

	const [view, setView] = useState('Pref');

	const { data: workplaceDepartments } = useQuery(
		['workplace-departments', workkplaceID],
		() => GetDepartmentsListOnWorkplace(workkplaceID),
		{
			onSuccess: (data) => {
				if (activeShift?.department.id) {
					const department = data.find((value) => value.department.id === activeShift?.department.id);
					setDepartemntSelected(department.department);
					formik.setFieldValue('role', activeShift?.role.id);
				}
			},
		}
	);

	const [departemntSelected, setDepartemntSelected] = useState<any>(null);

	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const [days, setDays] = useState<IDays[]>([
		{ id: 7, label: 'S', active: false, date: null },
		{ id: 1, label: 'M', active: false, date: null },
		{ id: 2, label: 'T', active: false, date: null },
		{ id: 3, label: 'W', active: false, date: null },
		{ id: 4, label: 'T', active: false, date: null },
		{ id: 5, label: 'F', active: false, date: null },
		{ id: 6, label: 'S', active: false, date: null },
	]);

	const { mutate: addShift } = useMutation(PostShifts, {
		onSuccess: (e) => {
			// for every mutate request succesfull add according role to it
			// can use it in mutate below because it will only run once
			// and we need it here to be run for every day selected in form
			addRole({ shift: e.id, role: formik.values.role });
		},
		onError: (error: Error) => {
			showToast('error', error.message);
		},
	});

	const { mutate: addRole } = useMutation(PostShiftsAddRole, {
		onSuccess: () => {
			showToast('success', 'Shift successfully added!');
			props.closeAvailibilityModal();
		},
		onError: (error: Error) => {
			showToast('error', error.message);
		},
	});
	const { mutate: deleteShift, isLoading: loadingDeleteShift } = useMutation(DeleteShift, {
		onSuccess: () => {
			showToast('success', 'Shift successfully deleted!');
			props.closeAvailibilityModal();
		},
		onError: () => {
			showToast('error');
		},
	});
	const { mutate: editShift } = useMutation(
		(props: { id: number; body: IShiftsPost }) => {
			return EditShifts(props.id, props.body);
		},
		{
			onSuccess: () => {
				showToast('success', 'Shift successfully edited!');
				props.closeAvailibilityModal();
			},
			onError: () => {
				showToast('error');
			},
		}
	);

	// TODO: why is this in useEffect?
	useEffect(() => {
		if (isMounted) {
			const currentDate = new Date(props.fromDate);
			const firstDay = currentDate.getDate() - currentDate.getDay();
			const firstdayDate = new Date(currentDate.setDate(firstDay));

			const updatedDays = days.map((day, index) => ({
				...day,
				date: new Date(firstdayDate.setDate(firstdayDate.getDate() + index)),
			}));

			setDays(updatedDays);
		}
	}, []);

	const formik = useFormik<any>({
		initialValues: getDefaultProps(activeShift, timezone),
		enableReinitialize: true,
		onSubmit: () => {
			if (isEditMode) {
				const date = new Date(activeShift.start_at);

				const start_at = createDateTime(date, formik.values.start_at);
				const end_at = createDateTime(date, formik.values.end_at);

				const shift = createShiftObject(start_at, end_at, dateFormat);

				return editShift({ ...shift, id: activeShift.id });
			}

			const shiftsToSend = generateArrayOfShifts();
			shiftsToSend.forEach((value) => {
				// send post request for creating shift
				addShift(value.body);
			});
		},
	});

	const generateArrayOfShifts = () => {
		// Filter active days
		const activeDays = days.filter((day) => day.active);
		const arrShifts: IPutOneShift[] = [];

		activeDays.forEach((day) => {
			const date = new Date(day.date!);
			const start_at = createDateTime(date, formik.values.start_at);
			const end_at = createDateTime(date, formik.values.end_at);

			const shift = createShiftObject(start_at, end_at, dateFormat);
			arrShifts.push(shift);
		});

		return arrShifts;
	};

	const createDateTime = (date, timeString) => {
		const dateTime = new Date(date);
		const [hours, minutes] = timeString.split(':');
		dateTime.setHours(parseInt(hours), parseInt(minutes));
		return dateTime;
	};

	window.moment = moment;

	const createShiftObject = (start_at, end_at, dateFormat) => {
		const newShift = {
			id: null,
			body: {
				workplace: { id: workkplaceID },
				time_card_id: 0,
				department: { id: parseInt(`${formik.values.department}`) },
				user: { id: auth.user.id },
				open: true,
				published: false,
				scheduled: false,
				labor: {
					wage_hourly_costs: 0,
					wage_hourly_overtime_costs: 0,
					scheduled_hours: 0,
				},
				start_at: moment.tz(moment(start_at).format(dateFormat), dateFormat, timezone).utc().toDate().toISOString(),
				end_at: moment.tz(moment(end_at).format(dateFormat), dateFormat, timezone).utc().toDate().toISOString(),
				note: formik.values.note || '',
				denied: false,
				shift_type: { id: view === 'Pref' ? 8 : 7 }, // use real ID later on
				reason: null,
				category: null,
				recurring: formik.values.recurring,
				billable: formik.values.billable,
				predicted: true,
				role: { id: formik.values.role },
			},
		};

		return newShift;
	};

	const selectDay = (id: number) => {
		const newArrDays = days.map((value) => {
			if (value.id === id) {
				value.active = !value.active;

				return {
					...value,
				};
			}

			return value;
		});

		setDays(newArrDays);
	};

	const handleDepartmentChose = (e: any) => {
		formik.handleChange(e);
		// select certain department for showing roles from that department
		// in select dropdown below
		let dep;
		workplaceDepartments?.forEach((value) => {
			if (value.department.id === parseInt(e.target.value)) {
				dep = value.department;
			}
		});
		setDepartemntSelected(dep);

		formik.setFieldValue('role', 0);
	};

	const handleRoleChange = (e: any) => {
		formik.handleChange(e);
	};

	const workspaceDepartmentOptions = useMemo(() => {
		return workplaceDepartments?.map(({ department }) => {
			if (!department?.roles || !auth?.role) {
				return null;
			}

			let optionValue = null;

			department.roles?.forEach((role) => {
				auth.role?.forEach((userRole) => {
					if (role.id === userRole.id) {
						// optionValue = (
						// <option value={department.id} key={department.id}>
						// 	{department.name}
						// </option>
						// );
						optionValue = {
							value: department.id,
							label: department.name,
						};
					}
				});
			});

			return optionValue;
		});
	}, [workplaceDepartments]);

	return (
		<>
			<div className={Forms.formTabGroup}>
				<p
					className={`${Forms.formTabGroupItem} ${view === 'Pref' ? Forms.formTabGroupItemActive : ''}`}
					onClick={() => setView('Pref')}
				>
					Preferred
				</p>
				<p
					className={`${Forms.formTabGroupItem} ${view === 'Unavailable' ? Forms.formTabGroupItemActive : ''}`}
					onClick={() => setView('Unavailable')}
				>
					Unavailable
				</p>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Department
				</label>
				<select
					className={Forms.formSelect}
					name="department"
					id="select"
					value={formik.values.department}
					onChange={handleDepartmentChose}
				>
					<option value={0} disabled>
						Choose department
					</option>

					{/* display departments that loged in employee has roles */}
					{workspaceDepartmentOptions?.map((department) => {
						return (
							<option value={department.value} key={department.value}>
								{department.label}
							</option>
						);
					})}
				</select>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="select">
					Role
				</label>
				<select
					className={Forms.formSelect}
					name="role"
					id="select"
					value={formik.values.role}
					onChange={handleRoleChange}
					disabled={formik.values.department === 0}
				>
					<option value="0" disabled>
						Choose role
					</option>

					{departemntSelected?.roles?.map((role: Item) => {
						return (
							<option value={role.id} key={role.id}>
								{role.name}
							</option>
						);
					})}
				</select>
			</div>
			<div className={Forms.formGroupSplit}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="select">
						From
					</label>
					<select
						className={Forms.formSelect}
						name="start_at"
						id="select"
						value={formik.values.start_at}
						onChange={formik.handleChange}
					>
						{generateTimeArray().map((value, index) => (
							<option value={value} key={index}>
								{value}
							</option>
						))}
						{/* <option value="1">1</option> */}
					</select>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="select">
						To
					</label>
					<select
						className={Forms.formSelect}
						name="end_at"
						id="select"
						value={formik.values.end_at}
						onChange={formik.handleChange}
						disabled={!(formik.values.start_at !== '00:00')}
					>
						{generateTimeArray(formik.values.start_at).map((value, index) => (
							<option value={value} key={index}>
								{value}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className={Forms.formGroup}>
				<label htmlFor="days" className={Forms.formLabel}>
					Days
				</label>
				<div className={Forms.formChecker}>
					{days.map((value, index) => {
						const className = (() => {
							const classes = [Forms.formCheckerItem];

							if (value.active) {
								classes.push(Forms.formCheckerItemActive);
							}

							return classes.join(' ');
						})();

						return (
							<button
								id="days"
								className={className}
								key={index}
								onClick={() => {
									selectDay(value.id);
								}}
								disabled={isEditMode}
							>
								<h5>{value.label}</h5>
							</button>
						);
					})}
				</div>
			</div>
			<div className={Forms.formGroup}>
				<label className={Forms.formLabel} htmlFor="textarea">
					Note
				</label>
				<textarea
					className={Forms.formTextarea}
					name="note"
					id="textarea"
					placeholder="Write note here"
					value={formik.values.note}
					onChange={formik.handleChange}
				></textarea>
			</div>
			<footer className={Forms.formFooter}>
				{isEditMode && (
					<button className={Buttons.btnDelete} onClick={() => setConfirmDeleteIsOpen(true)}>
						<SvgIcon spriteUrl={bin} className={Icons.btnErrorIcon} />
						Delete
					</button>
				)}
				<button className={Buttons.btnText} onClick={() => setIsDialogOpen(true)}>
					Cancel
				</button>
				<button
					className={Buttons.btnPrimary}
					onClick={() => formik.handleSubmit()}
					disabled={!formik.values.department || !formik.values.role}
					type="submit"
				>
					Save request
				</button>
			</footer>
			{isDialogOpen && (
				<SaveChangesDialog
					save={formik.handleSubmit}
					dontsave={props.closeAvailibilityModal}
					goback={setIsDialogOpen}
					isSubmitting={formik.isSubmitting}
				/>
			)}
			{confirmDeleteIsOpen ? (
				<ConfirmDialogBox
					type="shift"
					isLoading={loadingDeleteShift}
					handleDeleteClick={() => deleteShift(activeShift?.id)}
					setConfirmDeleteIsOpen={setConfirmDeleteIsOpen}
				/>
			) : null}
		</>
	);
}
