import { useFormik } from 'formik';
import { FC, useState } from 'react';

import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';
import CustomerDatabaseStyle from './CustomerDatabaseModal.module.scss';

import DateRangePicker from 'rsuite/DateRangePicker';
import moment from 'moment';
import { useQuery } from 'react-query';
import { searchEvents } from 'core/API/events';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import Loading from 'core/components/Loading/Loading';
import { IoMdDocument, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface ICoverRequestModal {
	closeModal: () => void;
}

interface ICustomerDB {
	name: string;
	email: string;
	phone: string;
	date_from: string;
	date_to: string;
}

const CustomerDatabaseModal: FC<ICoverRequestModal> = () => {
	const { workkplaceID, timezone } = useWorkplacesContext();

	const [page, setPage] = useState(1);

	const { values, handleChange, setFieldValue, resetForm } = useFormik<ICustomerDB>({
		initialValues: {
			name: '',
			email: '',
			phone: '',
			date_from: '',
			date_to: '',
		},
		onSubmit: () => {
			refetch();
		},
	});

	const { data, isLoading, refetch } = useQuery(
		['event-search', values.name, values.email, values.phone, values.date_from, values.date_to, page],
		() => {
			const FORMATDATE = 'YYYY-MM-DD HH:mm:ss';

			return searchEvents({
				workplaceID: workkplaceID,
				organizer_full_name: values.name,
				organizer_email: values.email,
				organizer_phone_number: values.phone,
				from: moment.tz(values.date_from, timezone).set({ hour: 5, minute: 0, second: 0 }).utc().format(FORMATDATE),
				to: moment
					.tz(values.date_to, timezone)
					.add(1, 'day')
					.set({ hour: 4, minute: 59, second: 59 })
					.utc()
					.format(FORMATDATE),
				page,
			});
		},
		{
			//enabled: false,
		}
	);

	const resetSearch = () => {
		resetForm();
		refetch();
	};

	return (
		<>
			<div className={Forms.formGroupSplitBig}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="organizersName">
						Requester&apos;s full name
					</label>
					<input
						className={Forms.formInputDefault}
						type="text"
						name="name"
						id="name"
						placeholder="Requester's full name"
						value={values.name}
						onChange={handleChange}
					/>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="organizersEmail">
						Requester&apos;s email
					</label>
					<input
						className={Forms.formInputDefault}
						type="text"
						name="email"
						id="email"
						placeholder="Requester's email"
						value={values.email}
						onChange={handleChange}
					/>
				</div>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} htmlFor="organizersPhoneNumber">
						Requester&apos;s phone number
					</label>
					<input
						className={Forms.formInputDefault}
						type="text"
						name="phone"
						id="phone"
						placeholder="Requester's phone number"
						value={values.phone}
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className={Forms.formGroupSplitBig}>
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel}>Select Date</label>
					<DateRangePicker
						showOneCalendar
						format="MM/dd/yyyy"
						isoWeek={true}
						onChange={(value) => {
							if (value) {
								setFieldValue('date_from', moment(value[0]).format('MM/DD/YYYY'));
								setFieldValue('date_to', moment(value[1]).format('MM/DD/YYYY'));
							}
						}}
						onClean={() => {
							setFieldValue('date_from', '');
							setFieldValue('date_to', '');
						}}
					/>
				</div>
			</div>
			<div className={Forms.formGroupSplitBig}></div>
			<div className={Forms.formFooter}>
				<button type="button" className={Buttons.btnText} onClick={resetSearch}>
					Reset
				</button>
				<button type="submit" className={Buttons.btnPrimary} onClick={() => refetch()}>
					Search
				</button>
			</div>
			<div className={Forms.formDivider} />
			{isLoading || data === undefined ? (
				<Loading />
			) : (
				<>
					<div>
						<span className={CustomerDatabaseStyle.BoxTextPurple}>{data.total_results} </span>
						<span className={CustomerDatabaseStyle.BoxTextGray}>
							Events found, sorted from newest Start date to the oldest
						</span>
					</div>
					{data.items.length ? (
						<div className={Forms.formFooter}>
							<p
								className={CustomerDatabaseStyle.BoxText}
								style={{
									display: 'flex',
									alignItems: 'center',
									lineHeight: '10px',
								}}
							>
								<span onClick={() => setPage((prevPage) => prevPage - 1)} style={{ cursor: 'pointer' }}>
									<IoIosArrowBack size={24} />
								</span>
								page {data.current_page} of {data.total_pages}{' '}
								<span onClick={() => setPage((prevPage) => prevPage + 1)} style={{ cursor: 'pointer' }}>
									<IoIosArrowForward size={24} />
								</span>
							</p>
						</div>
					) : null}
					{data.items.map((event) => (
						<div className={CustomerDatabaseStyle.Box} key={event.id}>
							<div className={CustomerDatabaseStyle.BoxFlexBetween}>
								<span className={CustomerDatabaseStyle.BoxText}>{event.name}</span>
								<span className={CustomerDatabaseStyle.BoxText}>
									{moment.tz(event.start, timezone).format('DD MMMM YYYY h:mm A')} -{' '}
									{moment.tz(event.end, timezone).format('DD MMMM YYYY h:mm A')}
								</span>
							</div>
							<span className={CustomerDatabaseStyle.BoxText}>
								Requests: {event.organizer_full_name}, {event.organizer_email}, {event.organizer_phone_number}
							</span>
							<div className={CustomerDatabaseStyle.BoxFlexBetween}>
								<span className={CustomerDatabaseStyle.BoxTextGray}>Note: {event.note}</span>
								<span>
									{event.menu ? (
										<a
											href={`${process.env.REACT_APP_URL}${event.menu}`}
											title={`Menu`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<IoMdDocument size={25} />
										</a>
									) : null}
									{event.banquet_event_order ? (
										<a
											href={`${process.env.REACT_APP_URL}${event.banquet_event_order}`}
											title={`BEO`}
											target="_blank"
											rel="noopener noreferrer"
										>
											<IoMdDocument size={25} />
										</a>
									) : null}
								</span>
							</div>
						</div>
					))}
				</>
			)}
		</>
	);
};

export default CustomerDatabaseModal;
