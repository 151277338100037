import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { fetchAllSituationRooms, fetchFilters, fetchTopPriority } from 'core/API/situationRoom';
import Loading from 'core/components/Loading/Loading';
import useModal from 'core/hooks/useModal';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';

import ActionList from '../components/ActionList/ActionList';
import AlertType from '../components/AlertType/AlertType';
import Filters from '../components/Filters/Filters';
import TakeActionDialog from '../components/TakeActionDialog/TakeActionDialog';
import { PrioritiesEnum } from '../enums';
// CSS modules
import SituationRooms from './SituationRoom.module.scss';

const SituationRoom: FC = () => {
	const { workkplaceID, data: workplacesData, timezone } = useWorkplacesContext();

	const { data: filtersData } = useQuery(['filters'], () => fetchFilters());

	const [lowPriorityItems, setLowPriorityItems] = useState([]);
	const [mediumPriorityItems, setMediumPriorityItems] = useState([]);
	const [highPriorityItems, setHighPriorityItems] = useState([]);
	const [urgentPriorityItems, setUrgentPriorityItems] = useState([]);
	const [filters, setFilters] = useState([]);

	useEffect(() => {
		if (filtersData) setFilters(filtersData?.items);
	}, [filtersData]);
	const [actionDialogData, setActionDialogData] = useState({});

	const {
		open: isTakeActionDialogOpen,
		openModal: openTakeActionDialog,
		closeModal: closeTakeActionDialog,
	} = useModal();

	const { isFetching, refetch: refetchSituationRooms } = useQuery(
		['situation-rooms'],
		() => fetchAllSituationRooms(workkplaceID, 1),
		{
			onSuccess: async ({ items }) => {
				setLowPriorityItems(items?.filter((item: any) => item.priority.id === 1 && item));

				setMediumPriorityItems(items?.filter((item: any) => item.priority.id === 2 && item));

				setHighPriorityItems(items?.filter((item: any) => item.priority.id === 3 && item));
				setUrgentPriorityItems(items?.filter((item: any) => item.priority.id === 4 && item));
			},
		}
	);

	const { data: currMaxPriority, refetch: refetchTopPriority } = useQuery(['top-priority'], () =>
		fetchTopPriority(workkplaceID)
	);

	const filterDataByPriority = (currMaxPriority: string) => {
		switch (currMaxPriority) {
			case PrioritiesEnum.LOW:
				return lowPriorityItems;
			case PrioritiesEnum.MEDIUM:
				return mediumPriorityItems;
			case PrioritiesEnum.HIGH:
				return highPriorityItems;
			case PrioritiesEnum.URGENT:
				return urgentPriorityItems;
			default:
				return;
		}
	};

	return isFetching ? (
		<Loading />
	) : (
		<>
			<div className={SituationRooms.wrapper}>
				<section className={SituationRooms.section}>
					<div className={SituationRooms.header}>
						<h4>Situation room</h4>
						<p className={SituationRooms.headerWorkplace}>
							{workplacesData?.items?.map(({ workplace }: any) => workplace.id === workkplaceID && workplace.name)}
						</p>
					</div>
					<Filters filters={filters} setFilters={setFilters} />
				</section>
				<section className={SituationRooms.sectionGroup}>
					<AlertType currMaxPriority={currMaxPriority?.top_priority} />
					<ActionList
						openTakeActionDialog={openTakeActionDialog}
						currMaxPriority={currMaxPriority?.top_priority}
						data={filterDataByPriority(currMaxPriority?.top_priority)}
						filters={filters}
						setActionDialogData={setActionDialogData}
						timezone={timezone}
					/>
				</section>
				<section className={SituationRooms.sectionGroupSplit}>
					{highPriorityItems.length > 0 && currMaxPriority?.top_priority !== PrioritiesEnum.HIGH && (
						<ActionList
							openTakeActionDialog={openTakeActionDialog}
							currMaxPriority={PrioritiesEnum.HIGH}
							data={highPriorityItems}
							filters={filters}
							setActionDialogData={setActionDialogData}
							timezone={timezone}
						/>
					)}
					{mediumPriorityItems.length > 0 && currMaxPriority?.top_priority !== PrioritiesEnum.MEDIUM && (
						<ActionList
							openTakeActionDialog={openTakeActionDialog}
							currMaxPriority={PrioritiesEnum.MEDIUM}
							data={mediumPriorityItems}
							filters={filters}
							setActionDialogData={setActionDialogData}
							timezone={timezone}
						/>
					)}
					{lowPriorityItems.length > 0 && currMaxPriority?.top_priority !== PrioritiesEnum.LOW && (
						<ActionList
							openTakeActionDialog={openTakeActionDialog}
							currMaxPriority={PrioritiesEnum.LOW}
							data={lowPriorityItems}
							filters={filters}
							setActionDialogData={setActionDialogData}
							timezone={timezone}
						/>
					)}
				</section>
			</div>
			{isTakeActionDialogOpen && (
				<TakeActionDialog
					close={() => closeTakeActionDialog()}
					data={actionDialogData}
					refetchSituationRooms={refetchSituationRooms}
					refetchTopPriority={refetchTopPriority}
				/>
			)}
		</>
	);
};

export default SituationRoom;
