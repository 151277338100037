import { useAuth } from 'module/auth/context/AuthContext';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useState } from 'react';

// CSS modules
import './ChooseWorkplace.scss';
import Buttons from 'styles/buttons.module.scss';
import Forms from 'styles/forms.module.scss';
import { Item } from 'module/workplaces/interface/WorkplacesInterface';

interface iChooseWorkplace {
	chooseWorkplace: () => void;
}

export default function ChooseWorkplace({ chooseWorkplace }: iChooseWorkplace): JSX.Element {
	const { data, setWorkplaceIDHandler } = useWorkplacesContext();
	const [workplace, setWorkplace] = useState('');
	const auth = useAuth();

	const setWorkplaceHandler = (e: any) => {
		setWorkplace(e.target.value);
		data?.items.forEach((value) => {
			if (value.workplace.id === +e.target.value) {
				console.log('selected workplace', value);
				setWorkplaceIDHandler(e.target.value, value.workplace.time_zone.label);
				if (value.manager) {
					auth.setIsManagerToLocale(true);
				} else {
					auth.setIsManagerToLocale(false);
				}
			}

			// save roles
			if (value.workplace.id === parseInt(e.target.value)) {
				auth.setRolesToLocal(value.role);
			}
		});
	};

	const submitButtonHandler = () => {
		if (workplace) {
			chooseWorkplace();
		}
	};

	return (
		<div className="FormBox" style={{ height: '330px' }}>
			<h3 style={{ marginBottom: 24 }}>Choose workplace</h3>
			<div className="formSelectBox">
				<div className={Forms.formGroup}>
					<label className={Forms.formLabel} style={{ fontWeight: 300 }} htmlFor="select">
						Workplace
					</label>
					<select
						className={Forms.formSelect}
						name="select"
						id="select"
						style={{ backgroundColor: '#fff' }}
						value={workplace}
						onChange={setWorkplaceHandler}
					>
						<option value="" disabled>
							Choose workplace
						</option>
						{data?.items?.map((workplace: Item) => {
							return (
								<option value={workplace.workplace.id} key={workplace.id}>
									{workplace.workplace.name}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			<button
				className={Buttons.btnPrimary}
				type="submit"
				onClick={submitButtonHandler}
				disabled={!workplace}
				style={{ marginTop: 24 }}
			>
				Continue
			</button>
		</div>
	);
}
