import { GetEmployeePerformance } from 'core/API/laborsaber';
import Loading from 'core/components/Loading/Loading';
import { getBusinessDay } from 'module/laborsaber/utils/utils';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { FC } from 'react';
import { useQuery } from 'react-query';

// CSS modules
import Sections from '../sections.module.scss';

const formatUserPerformance = (row) => {
	const price = parseFloat(row.price);
	const tip = parseFloat(row.tip);
	const void_amount = parseFloat(row.void);

	const hours_worked = parseFloat(row.hours_worked || 0);
	const sales_per_hour = hours_worked === 0 || price === 0 ? '-' : price / hours_worked;

	const discount_amount = parseFloat(row.discount_amount);

	const calculateTip = (tip / price) * 100;
	const calculateAmount = (void_amount / price) * 100;
	const calculateDiscount = (discount_amount / price) * 100;

	return {
		price,
		tip,
		hours_worked,
		sales_per_hour,
		void_amount,
		discount_amount,
		tardiness: row.tardiness || '-',
		calculateTip,
		calculateAmount,
		calculateDiscount,
	};
};

const EmployeePerformance: FC = () => {
	const { workkplaceID, timezone } = useWorkplacesContext();

	const { isLoading, data: empData } = useQuery(
		'employeePerformance',
		() => {
			const [firstBusinessDay, lastBusinessDay] = getBusinessDay(timezone);

			return GetEmployeePerformance({
				workkplaceID,
				formatedFirstDay: firstBusinessDay,
				formatedLastDay: lastBusinessDay,
			});
		}
	);

	console.log('empData:', empData);

	return (
		<article
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'emplPerf',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{isLoading ? (
				<div>
					<Loading />
				</div>
			) : (
				<>
					<h4 style={{ paddingLeft: '16px' }}>Weekly Employee Performance</h4>
					<div style={{ display: 'flex', overflow: 'auto' }}>
						<div className={Sections.table}>
							<div className={Sections.tableHeader}>
								<span>Employee</span>
								<span>Total Sales</span>
								<span>Weekly Hours</span>
								<span>Sales Per Hour</span>
								<span>Tardiness</span>
								<span>Avg Tips %</span>
								<span>Void %</span>
								<span>Discounts %</span>
							</div>
							<div className={Sections.tableBody}>
								{!empData?.items?.length ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											minHeight: '300px',
										}}
									>
										No data
									</div>
								) : null}
								{empData?.items?.map((row) => {
									const {
										price,
										hours_worked,
										sales_per_hour,
										tardiness,
										calculateTip,
										calculateAmount,
										calculateDiscount,
									} = formatUserPerformance(row);

									return (
										<div key={row.employee_id} className={Sections.tableRow}>
											<div>
												{row.first_name} {row.last_name}
											</div>
											<div>${price.toFixed(2)}</div>
											<div>{hours_worked}</div>
											<div>{sales_per_hour}</div>
											<div>{tardiness}</div>
											<div>{calculateTip.toFixed(2) || 0}%</div>
											<div>{calculateAmount.toFixed(2) || 0}%</div>
											<div>{calculateDiscount.toFixed(2) || 0}%</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</>
			)}
		</article>
	);
};

export default EmployeePerformance;
