import { FC, useState } from 'react';

import EmployeeClockEntries from '../components/EmployeeClockEntries/EmployeeClockEntries';
import EmployeePerformance from '../components/EmployeePerformance/EmployeePerformance';
import SalesGraph from '../components/SalesGraph/SalesGraph';

// CSS modules
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import moment from 'moment';
import { useQuery } from 'react-query';
import DailyLaborCost from '../components/DailyLaborCost/DailyLaborCost';
import NetSales from '../components/NetSales/NetSales';
import Ls from './Laborsaber.module.scss';
import { getSalesInfo } from './Laborsaber.utils';

const Laborsaber: FC = () => {
	const { workkplaceID, data: workplacesData, timezone } = useWorkplacesContext();

	const [percentage, setPercentage] = useState(0);

	const { isLoading, data: Sales } = useQuery('SalesInfo', () => getSalesInfo(workkplaceID, timezone), {
		refetchInterval: 1000 * 60 * 15, // 15 min
		refetchIntervalInBackground: true,
		onSuccess: (data) => {
			const ProjectedCost = data.daily_labor_cost.today.projected_labor_cost;

			let shiftCalculation = 0;

			data.situation_room.items.forEach((employee) => {
				data.employee_attendance.items.forEach((employee_attendance) => {
					if (employee_attendance.user.id === employee.labor.user_id) {
						const calculateHours = Math.ceil(
							moment(employee_attendance.employee_left).diff(employee.labor.proposed_cut_time, 'minutes') / 60
						);

						shiftCalculation += calculateHours * parseInt(employee.labor.wage_hourly_costs);
					}
				});
			});

			const percentage = (shiftCalculation / ProjectedCost) * 100;

			if (!isFinite(percentage)) setPercentage(0);

			setPercentage(percentage || 0);
		},
	});

	return (
		<>
			<section className="Content">
				<div
					style={{
						width: '100%',
						minHeight: 'calc(100vh - 148px)',
						background: '#ffffff',
						padding: '24px 24px 40px 24px',
						borderRadius: '8px',
					}}
				>
					<section style={{ maxWidth: '100%' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<p
								style={{
									marginLeft: '16px',
									fontFamily: 'Gotham',
									fontStyle: 'normal',
									fontSize: '22px',
									lineHeight: '32px',
									fontWeight: 400,
									letterSpacing: '-1px',
									color: '#841d80',
								}}
							>
								{workplacesData?.items?.map(({ workplace }: any) => workplace.id === workkplaceID && workplace.name)}
							</p>
						</div>
					</section>
					<section className={Ls.laborsaberGrid}>
						<SalesGraph />
						<NetSales isLoading={isLoading} sales={Sales} />
						<DailyLaborCost isLoading={isLoading} sales={Sales} />
						<EmployeePerformance />
						<EmployeeClockEntries />
						{/* <OrderEvents data={orderEventsData} /> */}
					</section>
				</div>
			</section>
		</>
	);
};

export default Laborsaber;
