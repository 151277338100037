import { useAuth } from 'module/auth/context/AuthContext';
import EmployeeRoot from 'module/employee/EmployeeRoot';
import { ROUTES } from 'module/navigation/enums/routes.enums';
import PreloadComp from 'module/preload/PreloadComp';
import Manager from 'module/schedule/Manager';
import { FC, useMemo } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import HeaderBar from '../HeaderBar/HeaderBar';

interface PrivateRouteProps {
	path: RouteProps['path'];
	component: React.ElementType;
}

export const PrivateRouteManager: FC<PrivateRouteProps> = ({ component: Component, children, ...rest }) => {
	const { isManager } = useAuth();

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return isManager ? (
					children ?? <Component {...routeProps} />
				) : (
					<Redirect
						to={{
							pathname: ROUTES.EMPLOYEE,
						}}
					/>
				);
			}}
		/>
	);
};

export const PrivateRouteEmployee: FC<PrivateRouteProps> = ({ component: Component, children, ...rest }) => {
	const { isManager } = useAuth();

	return (
		<Route
			{...rest}
			render={(routeProps) => {
				return isManager ? (
					<Redirect
						to={{
							pathname: ROUTES.MANAGER,
						}}
					/>
				) : (
					children ?? <Component {...routeProps} />
				);
			}}
		/>
	);
};

export default function Layout(): JSX.Element {
	const renderLayout = useMemo(
		() => (
			<>
				<HeaderBar />
				<main style={{ margin: '0 24px 48px 24px', overflow: 'hidden' }}>
					<Switch>
						<PrivateRouteManager path={ROUTES.MANAGER} component={Manager} />
						<PrivateRouteEmployee path={ROUTES.EMPLOYEE} component={EmployeeRoot} />
						<Redirect from="/" to={ROUTES.MANAGER} />
					</Switch>
				</main>
			</>
		),
		[]
	);
	return <PreloadComp>{renderLayout}</PreloadComp>;
}
