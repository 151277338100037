import { FC, useMemo } from 'react';

import { Line } from 'react-chartjs-2';

import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { useSalesGraph } from './SalesGraph.hook';
import { getBusinessDay } from 'module/laborsaber/utils/utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const x = 15; //minutes interval
const times = []; // time array
let tt = 300; // start time
const ap = ['AM', 'PM']; // AM-PM

//loop to increment the time and push results in array
for (let i = 0; tt < 29 * 60; i++) {
	const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
	const mm = tt % 60; // getting minutes of the hour in 0-55 format
	times[i] =
		('0' + (hh % 12)).slice(-2) + ':' + ('0' + mm).slice(-2) + ap[Math.floor(hh / 12) <= 1 ? Math.floor(hh / 12) : 0]; // pushing data in array in [00:00 - 12:00 AM/PM format]
	tt = tt + x;
}

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top' as const,
			labels: {
				font: {
					family: 'Gotham',
					size: 16,
					// weight: '400',
				},
			},
		},
		title: {
			display: false,
			text: 'Chart.js Line Chart',
		},
		tooltip: {
			padding: 10,
			titleFont: {
				family: 'Gotham',
				size: 16,
			},
			bodyFont: {
				family: 'Gotham',
				size: 14,
			},
		},
		// caretPadding: 10,
		// padding: {
		//     top: 5,
		//     right: 14,
		//     bottom: 5,
		//     left: 14,
		// },
	},
	scales: {
		y: {
			ticks: {
				// eslint-disable-next-line
				callback: (value: any): string => {
					return '$ ' + value;
				},
				font: {
					family: 'Gotham',
					// size: 12,
				},
			},
		},
		x: {
			ticks: {
				font: {
					family: 'Gotham',
					// size: 12,
				},
			},
		},
	},
};

// const labels = [...times];

// export const data = {
//     labels,
//     datasets: [
//         {
//             label: 'Predicted sales',
//             data: labels.map(() => Math.floor(Math.random() * 500)),
//             borderColor: '#4789C8',
//             backgroundColor: '#4789C8',
//             borderDash: [5, 5],
//         },
//         {
//             label: 'Real-time sales',
//             data: labels.map(() => Math.floor(Math.random() * 500)),
//             borderColor: '#841D80',
//             backgroundColor: '#841D80',
//         },
//     ],
// };

// interface IDataset {
//     label?: string;
//     data?: number[];
//     borderColor?: string;
//     backgroundColor?: string;
//     borderDash?: number[];
// }

// interface IGraphSalesData {
//     labels: string[];
//     datasets: IDataset[];
// }

const SalesGraph: FC = () => {
	const { workkplaceID, timezone } = useWorkplacesContext();

	const [firstBusinessDay, lastBusinessDay] = getBusinessDay(timezone);
	const { isLoading, data } = useSalesGraph(workkplaceID, firstBusinessDay, lastBusinessDay);

	const empData = useMemo(() => {
		let comulSumNumber = 0;

		const timesArr = [];
		const tempPredictedSalesData = [];
		const tempRealSalesData = [];

		data?.items.forEach((value) => {
			timesArr.push(moment.tz(value.time_slot, timezone).format('HH:mm A'));

			tempPredictedSalesData.push(parseFloat(value.predicted));
			tempRealSalesData.push(parseFloat(value.actual) + comulSumNumber);
			comulSumNumber += parseFloat(value.actual);
		});

		return {
			labels: [...timesArr],
			datasets: [
				{
					label: 'Predicted sales',
					data: [...tempPredictedSalesData],
					borderColor: '#4789C8',
					backgroundColor: '#4789C8',
					borderDash: [5, 5],
				},
				{
					label: 'Real-time sales',
					data: [...tempRealSalesData],
					borderColor: '#841D80',
					backgroundColor: '#841D80',
				},
			],
		};
	}, [data]);

	return (
		<article
			style={{
				width: '100%',
				height: '100%',
				borderRadius: '8px',
				padding: '24px',
				boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.1)',
				background: '#ffffff',
				gridArea: 'salesGraph',
			}}
		>
			<h4 style={{ paddingLeft: '16px' }}>Sales chart</h4>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{/* <div className={Buttons.btnToggleThree}>
                    <button
                        type="button"
                        className={Buttons.btnToggleActiveThree}
                        onClick={() => console.log('Simple sales')}
                    >
                        Simple sales
                    </button>
                    <button
                        type="button"
                        className={Buttons.btnToggleDefaultThree}
                        onClick={() => console.log('Employee Layered Sales')}
                    >
                        Employee Layered Sales
                    </button>
                    <button
                        type="button"
                        className={Buttons.btnToggleDefaultThree}
                        onClick={() => console.log('Net % Labor Cost')}
                    >
                        Net % Labor Cost
                    </button>
                </div> */}
				{/* <div className={Sections.legend}>
                    <div className={Sections.legendItem}>
                        <GraphIndicator />
                        <span className={Sections.legendLabel}>
                            Predicted sales
                        </span>
                    </div>
                    <div className={Sections.legendItem}>
                        <GraphIndicator />
                        <span className={Sections.legendLabel}>
                            Real-time sales
                        </span>
                    </div>
                </div> */}
			</div>
			<div
				style={{
					width: '100%',
					height: '300px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '27px',
				}}
			>
				{/* <GraphPlaceholder /> */}
				{isLoading ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '400px',
						}}
					>
						<ClipLoader color="#841D80" loading={true} size={50} />
					</div>
				) : (
					<Line options={options} data={empData} />
				)}
			</div>
		</article>
	);
};

export default SalesGraph;
