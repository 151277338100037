import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

interface QueryParams {
	[key: string]: string;
}

interface UseQueryParamsHook {
	query: QueryParams;
	change: (newParams: QueryParams) => void;
}

const useQueryParams = (): UseQueryParamsHook => {
	const location = useLocation();
	const history = useHistory();
	const [query, setQueryParams] = useState<QueryParams>({});

	useEffect(() => {
		// Parse current query parameters from the URL
		const searchParams = new URLSearchParams(location.search);
		const params: QueryParams = {};

		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		// Update state with current query parameters
		setQueryParams(params);
	}, [location.search]);

	const change = (newParams: QueryParams) => {
		// Create a new URLSearchParams object with the updated parameters
		const searchParams = new URLSearchParams(location.search);

		for (const key in newParams) {
			if (newParams[key]) {
				if (newParams[key]) {
					searchParams.set(key, newParams[key]);
				} else {
					searchParams.delete(key);
				}
			}
		}

		// Update the URL with the new query parameters
		history.push({ search: searchParams.toString() });
	};

	return { query, change };
};

export default useQueryParams;
