import style from './CommentComp.module.scss';
import questionStyle from '../QuestionComp/QuestionComp.module.scss';
import FooterButtons, { IFooterButtons } from '../FooterButtons/FooterButtons';

interface CommentComp extends IFooterButtons {
	numberOfQuestion: number;
	questionsLength: number;
	commentHandler: (e: any) => void;
}

export default function CommentComp({
	numberOfQuestion,
	questionsLength,
	commentHandler,
	goNext,
	goBack,
}: CommentComp): JSX.Element {
	return (
		<>
			<div className={questionStyle.TextInfo}>
				<div className={questionStyle.firstColumn}>
					<p className={questionStyle.infoSmall}>Your opinion is important to us!</p>
					<p className={questionStyle.infoBig}>Is there anything you would like us to know, please comment?</p>
				</div>
				<div className={questionStyle.secondColumn}>
					{numberOfQuestion}/{questionsLength}
				</div>
			</div>
			<textarea
				name="comment"
				className={style.Comment}
				placeholder="Leave a comment"
				onChange={commentHandler}
			></textarea>

			<FooterButtons
				goNext={goNext}
				goBack={goBack}
				isDisabled={false}
				showPrevious={numberOfQuestion !== 1}
				label="Finish"
			/>
		</>
	);
}
