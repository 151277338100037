import React, { useState } from 'react';

interface IToastProvider {
	show: boolean;
	type: Types;
	text: string;
	showToast: (type: Types, text?: string) => void;
	closeToast: () => void;
}

type Types = 'success' | 'error';

const ToastContext = React.createContext<IToastProvider>(undefined!);

export function ToastContextProvidet({ children }: { children: React.ReactNode }): JSX.Element {
	const { show, showToast, closeToast, type, text } = useProviderFunctions();
	return <ToastContext.Provider value={{ show, showToast, closeToast, type, text }}>{children}</ToastContext.Provider>;
}

function useProviderFunctions() {
	const [show, setShow] = useState<boolean>(false);
	const [type, setType] = useState<Types>('success');
	const [text, setText] = useState<string>('Something went wrong!');

	const showToast = (type: Types, text?: string) => {
		setShow(true);
		setType(type);
		if (text) {
			setText(text);
		}
		setTimeout(() => {
			setShow(false);
		}, 3000);
	};

	const closeToast = () => {
		setShow(false);
	};

	return {
		show,
		showToast,
		closeToast,
		type,
		text,
	};
}

export const useToastContext = (): IToastProvider => React.useContext(ToastContext);
