import { useMobileDetect } from 'core/hooks/useMobileDetect';
import { useEffect, useRef, useState } from 'react';
import DesktopPage from './DesktopPage/DesktopPage';
import './EmployeeScheduler.style.scss';
import MobilePage from './MobilePage/MobilePage';

import cover from 'assets/icons/Cover.svg';
import shiftPick from 'assets/icons/ShiftPick.svg';
import timeOff from 'assets/icons/TimeOff.svg';
import wavingHand from 'assets/icons/WavingHand.svg';

import { MbscCalendarEvent, MbscEventClickEvent } from '@mobiscroll/react';
import { GetSchedules } from 'core/API/schedules';
import { GetOneShift, PutOneShift } from 'core/API/shifts';
import Modal from 'core/components/Modal/Modal';
import { useToastContext } from 'core/components/Toast/context/ToastContext';
import { useIsMount } from 'core/hooks/useIsMounted';
import useModal from 'core/hooks/useModal';
import useQueryParams from 'core/hooks/useQueryParams';
import { useAuth } from 'module/auth/context/AuthContext';
import CoverRequestModal from 'module/schedule/Modals/CoverRequestModal/CoverRequestModal';
import TimeOffRequestModal from 'module/schedule/Modals/TimeOffRequestModal/TimeOffRequestModal';
import Navigation from 'module/schedule/components/Navigation/Navigation';
import { useWorkplacesContext } from 'module/workplaces/context/WorkplacesContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import getTimeWithTimezone from 'shared/utils/dates/formatTimeWithTimezone';
import AvailabilityModal from './AvailabilityModal/AvailabilityModal';
import ShiftPickRequest from './ShiftPickRequest/ShiftPickRequest';

const EmployeeScheduler = (): JSX.Element => {
	const { workkplaceID, timezone } = useWorkplacesContext();
	const { showToast } = useToastContext();
	// const { push, location } = useHistory();
	const { query } = useQueryParams();

	// const defaultView = useMemo(() => {
	// 	const queryString = new URLSearchParams(location.search);
	// 	const queryParamsObject: { view?: string } = {};

	// 	for (const [key, value] of queryString.entries()) {
	// 		queryParamsObject[key] = value;
	// 	}

	// 	return queryParamsObject.view;
	// }, [location]);

	const isMounted = useIsMount();
	const auth = useAuth();

	const {
		open: isAvailibilityModalOpen,
		openModal: openAvailabilityModal,
		closeModal: closeAvailibilityModal,
	} = useModal();

	const queryClient = useQueryClient();
	const { open: isShiftPickModalOpen, openModal: openShiftPickModal, closeModal: closeShiftPickModal } = useModal();

	const { open: isTimeOffModalOpen, openModal: openTimeOffModal, closeModal: closeTimeOffModal } = useModal();

	const { open: isCoverModalOpen, openModal: openCoverModal, closeModal: closeCoverModal } = useModal();

	const [menuView, setMenuView] = useState<'regular' | 'trade'>('regular');
	const [fromDate, setFromDate] = useState('');
	const [toDate, setToDate] = useState('');

	const selectedAvailability = useRef<MbscCalendarEvent | null>(null);

	const [myEvents, setEvents] = useState<MbscCalendarEvent[]>([]);
	const [myEventsUnassigned, setEventsUnassigned] = useState<any>([]);

	const [tradeMyShift, setTradeMyShift] = useState<MbscCalendarEvent | null>(null);
	const [tradeOtherShift, setTradeOtherShift] = useState<MbscCalendarEvent | null>(null);

	const [shiftPickID, setShiftPickID] = useState(0);

	const { refetch: refetchSchedules } = useQuery(
		['schedule', workkplaceID, query.view, fromDate],
		() =>
			GetSchedules({
				workplace: workkplaceID,
				from: fromDate,
				to: toDate,
			}),
		{
			refetchInterval: 600000, // 10 min interval for refresh
			enabled: !!fromDate && !!toDate,
			onSuccess: async (data) => {
				const eventsObj: any = {};
				const customEvents: any = [];
				const unassignedEvents: any = [];

				// sort events for each user
				await data.items.forEach((event) => {
					if (event.user === null) {
						// check if loged user has roles

						// push array
						unassignedEvents.push(event);
					} else {
						if (eventsObj[event.user.id]) {
							eventsObj[event.user.id].push(event);
						} else {
							eventsObj[event.user.id] = [event];
						}
					}
				});

				for (const property in eventsObj) {
					await eventsObj[property].forEach((current: any, index: number) => {
						if (current.scheduled) {
							customEvents.push({
								shift_id: current.id,
								id: current?.user?.id + index, // used for key in mobiscroll list
								resource: current?.user?.id ? current?.user?.id : 0,
								start: current.start_at,
								end: current.end_at,
								title: `${current?.user?.first_name} ${current?.user?.last_name}`,
								editable: false,
								role: current?.roles[0]?.name,
								published: current?.published,
								scheduled: current.scheduled,
								isTradeSelected: false,
								isOpenShift: false,
								color: current.roles[0]?.background_color,
								background_color: current.roles[0]?.background_color,
								text_color: current.roles[0]?.text_color,
								owner: auth.user.id === current.user.id,
							});
						} else {
							customEvents.push({
								shift_id: current.id,
								id: current?.user?.id + index, // used for key in mobiscroll list
								resource: current?.user?.id ? current?.user?.id : 0,
								start: current.start_at,
								end: current.end_at,
								title: `${current?.user?.first_name} ${current?.user?.last_name}`,
								// this is availability
								editable: true,
								role: current?.role.name,
								// role: current?.roles[0]?.name,
								published: current?.published,
								scheduled: current.scheduled,
								isTradeSelected: false,
								isOpenShift: false,
								color: current.roles[0]?.background_color,
								background_color: current.roles[0]?.background_color,
								text_color: current.roles[0]?.text_color,
								owner: auth.user.id === current.user.id,
							});
						}
					});
				}

				if (useMobileDetect().isMobile()) {
					const combinedArrayOfEvents = [...customEvents];
					unassignedEvents.forEach((shift: any, index: number) => {
						combinedArrayOfEvents.push({
							shift_id: shift.id,
							id: shift?.user ? shift?.user?.id + index : 0 + index, // used for key in mobiscroll list
							resource: shift?.user?.id ? shift?.user?.id : 0,
							start: shift.start_at,
							end: shift.end_at,
							title: `Open shift`,
							editable: false,
							role: shift?.roles[0]?.name,
							published: shift?.published,
							scheduled: shift.scheduled,
							isOpenShift: true,
						});
					});
					setEvents(combinedArrayOfEvents);
				} else {
					setEvents(customEvents);
					setEventsUnassigned(unassignedEvents);
				}
			},
			onError: (e: any) => {
				showToast('error', e.error);
			},
		}
	);

	const { mutate: updateOneShift } = useMutation(PutOneShift);

	const { refetch: refetchMyShiftData, isLoading: isLoadingMyShift } = useQuery(
		['trade-shift', tradeMyShift?.shift_id],
		() => GetOneShift(tradeMyShift?.shift_id),
		{
			enabled: false,
			onSuccess: (data) => {
				const { id, time_card_id, ...rest } = data;
				if (tradeOtherShift && tradeMyShift) {
					updateOneShift(
						{
							id,
							body: {
								...rest,
								scheduled: false,
								user: { id: +tradeOtherShift.resource! },
								shift_type: { id: 6 },
								note: `${tradeMyShift.title} made trade request with ${tradeOtherShift?.title}`,
								time_card_id: parseInt(time_card_id),
							},
						},
						{
							onSuccess: () => {
								showToast('success', 'Shift trade requested!');
								setMenuView('regular');
								refetchSchedules();
							},
							onError: () => {
								showToast('error');
							},
						}
					);
				}
			},
			onError: (e: any) => {
				showToast('error', `${e?.error}`);
			},
		}
	);

	const { refetch: refetchOtherShiftData, isLoading: isLoadingOtherShift } = useQuery(
		['trade-shift', tradeOtherShift?.shift_id],
		() => GetOneShift(tradeOtherShift?.shift_id),
		{
			enabled: false,
			onSuccess: (data) => {
				const { id, time_card_id, ...rest } = data;
				if (tradeMyShift) {
					updateOneShift(
						{
							id,
							body: {
								...rest,
								scheduled: false,
								user: { id: +tradeMyShift.resource! },
								shift_type: { id: 6 },
								note: `${tradeMyShift.title} made trade request with ${tradeOtherShift?.title}`,
								time_card_id: parseInt(time_card_id),
							},
						},
						{
							onSuccess: () => {
								showToast('success', 'Shift trade requested!');
								setMenuView('regular');
								refetchSchedules();
							},
							onError: () => {
								showToast('error');
							},
						}
					);
				}
			},
			onError: (e: any) => {
				showToast('error', `${e?.error}`);
			},
		}
	);

	useEffect(() => {
		let firstday;
		let lastday;
		if (query.view === 'day' && !useMobileDetect().isMobile()) {
			const today = new Date();

			firstday = new Date(today);
			firstday.setHours(0, 0, 0, 0);

			lastday = new Date(today);
			lastday.setHours(23, 59, 59, 999);
		} else {
			const curr = new Date(); // get current date
			const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week

			firstday = new Date(curr.setDate(first));
			lastday = new Date(firstday);
			lastday.setDate(firstday.getDate() + 6);
		}

		const [formatedFirstDay, formatedLastDay] = getTimeWithTimezone(firstday, lastday, timezone);

		setFromDate(formatedFirstDay);
		setToDate(formatedLastDay);
	}, []);

	useEffect(() => {
		if (!isMounted) {
			refetchSchedules();
		}
	}, [fromDate, toDate]);

	const updateTimeRange = (firstday: Date, lastday: Date) => {
		const [formatedFirstDay, formatedLastDay] = getTimeWithTimezone(firstday, lastday, timezone);

		setFromDate(formatedFirstDay);
		setToDate(formatedLastDay);
	};

	const handleOpenShiftClick = (ID: number) => {
		setShiftPickID(ID);
		openShiftPickModal();
	};

	const closeShiftPickModalHandler = () => {
		closeShiftPickModal();
		refetchSchedules();
	};

	const closeTimeOffModalHandler = () => {
		closeTimeOffModal();
		refetchSchedules();
	};

	const closeCoverModalHandler = () => {
		closeCoverModal();
		refetchSchedules();
	};

	const closeAvailibilityModalHandler = () => {
		selectedAvailability.current = null;
		closeAvailibilityModal();
		refetchSchedules();
	};

	const tradeMenuClickHandler = () => {
		setMenuView('trade');
		const oldEvents = [...myEvents];

		const newArr = oldEvents.map((values) => ({
			...values,
			isTradeSelected: true,
		}));

		setEvents(newArr);
	};

	const handleTradePickingShift = (shift: any) => {
		if (tradeMyShift === null) {
			// select first shift (My shift)
			if (auth.user.id === shift.resource) {
				setTradeMyShift(shift);
				const oldEvents = [...myEvents];

				const newArr = oldEvents.map((values) => ({
					...values,
					isTradeSelected: shift.shift_id === values.shift_id || !values.isTradeSelected ? false : true,
				}));

				setEvents(newArr);
			} else {
				showToast('error', 'Please first select your shift!');
			}
		} else if (tradeOtherShift === null) {
			setTradeOtherShift(shift);
			const oldEvents = [...myEvents];

			const newArr = oldEvents.map((values) => ({
				...values,
				isTradeSelected: shift.shift_id === values.shift_id || !values.isTradeSelected ? false : true,
			}));

			setEvents(newArr);
		}
	};

	const startTradeShiftHandler = () => {
		if (tradeMyShift && tradeOtherShift) {
			refetchMyShiftData();
			refetchOtherShiftData();
		} else {
			showToast('error', 'Select other shift!');
		}
	};

	const onScheduleEventClick = (e: MbscEventClickEvent) => {
		if (e.event.editable) {
			selectedAvailability.current = e.event;
			console.log('e.event:', e);
			prefetchShift(e.event.shift_id).then(() => {
				openAvailabilityModal();
			});
		}
	};

	const prefetchShift = async (shiftID) => {
		// The results of this query will be cached like a normal query
		await queryClient.prefetchQuery({
			queryKey: `shifts/${shiftID}`,
			queryFn: () => {
				if (shiftID === null) {
					return null;
				}
				return GetOneShift(shiftID);
			},
		});
	};

	return (
		<section className="Content">
			<div className="draggable">
				{useMobileDetect().isMobile() ? (
					<MobilePage
						myEvents={myEvents}
						updateTimeRange={updateTimeRange}
						handleOpenShiftClick={handleOpenShiftClick}
						handleTradePickingShift={handleTradePickingShift}
					/>
				) : (
					<DesktopPage
						events={myEvents}
						onScheduleEventClick={onScheduleEventClick}
						updateTimeRange={updateTimeRange}
						handleTradePickingShift={handleTradePickingShift}
						tradeMyShift={tradeMyShift}
					/>
				)}
			</div>
			<Navigation
				menuView={menuView}
				setMenuView={setMenuView}
				schedulesUnassigned={myEventsUnassigned}
				handleOpenShiftClick={handleOpenShiftClick}
				tradeMyShift={tradeMyShift}
				refetchSchedules={refetchSchedules}
				tradeOtherShift={tradeOtherShift}
				isLoadingMyShift={isLoadingMyShift}
				isLoadingOtherShift={isLoadingOtherShift}
				startTradeShiftHandler={startTradeShiftHandler}
				setTradeMyShift={setTradeMyShift}
				setTradeOtherShift={setTradeOtherShift}
				tradeMenuClickHandler={tradeMenuClickHandler}
				openAvailabilityModal={openAvailabilityModal}
				openTimeOffModal={openTimeOffModal}
				openCoverModal={openCoverModal}
			/>
			{isAvailibilityModalOpen ? (
				<Modal
					icon={wavingHand}
					title="Request availability"
					close={closeAvailibilityModalHandler}
					render={() => (
						<AvailabilityModal
							fromDate={fromDate}
							defaultData={selectedAvailability.current}
							closeAvailibilityModal={closeAvailibilityModalHandler}
						/>
					)}
				/>
			) : null}
			{isShiftPickModalOpen ? (
				<Modal
					icon={shiftPick}
					title="Shift pick"
					close={closeShiftPickModal}
					render={() => (
						<ShiftPickRequest shiftPickID={shiftPickID} closeShiftPickModalHandler={closeShiftPickModalHandler} />
					)}
				/>
			) : null}

			{isTimeOffModalOpen ? (
				<Modal
					icon={timeOff}
					title="Request time off"
					close={closeTimeOffModalHandler}
					render={() => <TimeOffRequestModal role={auth.isManager} closeModal={closeTimeOffModalHandler} />}
				/>
			) : null}

			{isCoverModalOpen ? (
				<Modal
					icon={cover}
					title="Request cover"
					close={closeCoverModal}
					render={() => (
						<CoverRequestModal role={auth.isManager} closeModal={closeCoverModalHandler} shifts={myEvents} />
					)}
				/>
			) : null}
		</section>
	);
};

export default EmployeeScheduler;
